<template>
  <div v-if="objdeil.jobInfo">
    <Heard />
    <div class="tophead container-fluid">
      <div class="head container">
        <div class="left">
          <p class="postname">{{ objdeil.jobInfo.jobName }}</p>
          <p class="postprice">{{ objdeil.jobInfo.umoney }}</p>
        </div>
        <div class="btn" @click="gopay">
          {{ objdeil.jobInfo.sendStatus == 0 ? "投递岗位" : "已投递" }}
        </div>
      </div>
    </div>
    <div class="body container">
      <div class="bodyleft">
        <p class="comtitle">基本信息</p>
        <div class="type">
          <p>
            工作性质 <span>{{ objdeil.jobInfo.jobType }}</span>
          </p>
          <p>
            招聘人数 <span>{{ objdeil.jobInfo.needNum }}</span>
          </p>
          <p>
            年龄要求 <span>{{ objdeil.jobInfo.age }}</span>
          </p>
          <p>
            学历要求 <span>{{ objdeil.jobInfo.edu }}</span>
          </p>
          <p>
            工作经验 <span>{{ objdeil.jobInfo.exp }}</span>
          </p>

          <p>
            工作地点
            <span>{{
              objdeil.jobInfo.address
                ? objdeil.jobInfo.address
                : objdeil.companyInfo.address
            }}</span>
          </p>
        </div>
        <p class="comtitle">职位详情</p>
        <div class="postdeil">
          {{ objdeil.jobInfo.welfare }}
        </div>
        <p class="comtitle">任职要求</p>
        <div class="postdeil">
          {{ objdeil.jobInfo.jobRequire }}
        </div>
        <p class="comtitle">工作地址</p>
        <baidu-map
          class="map"
          :dragging="dragging"
          :center="center"
          :zoom="zoom"
          style="height: 400px; width: 720px; margin-bottom: 48px"
          :scroll-wheel-zoom="scroll"
        >
          <bm-info-window
            :position="center"
            :title="title"
            :show="show"
            style="
              height: 40px;
              width: 280px;
              font-size: 10px;
              border-radius: 8px;
            "
          >
            <p class="bdwtext" v-html="contents"></p>
          </bm-info-window>
        </baidu-map>
        <p class="comtitle">联系方式</p>
        <div class="call">
          <!-- <p>电话:</p>
          <p>座机:</p> -->
          <p>
            邮箱:
            {{
              objdeil.companyInfo.email ? objdeil.companyInfo.email : "暂未公开"
            }}
          </p>
        </div>
      </div>
      <div class="bodyrg">
        <p class="comtitle">公司信息</p>
        <div class="picbox">
          <img
            :src="
              objdeil.companyInfo.logoImgPath
                ? objdeil.companyInfo.logoImgPath
                : require('@/assets/image/baseicon.png')
            "
            alt=""
          />
          {{ objdeil.companyInfo.companyName }}
        </div>
        <div class="ty">
          <p>
            性质 <span>{{ objdeil.companyInfo.comType }}</span>
          </p>
          <p>
            行业 <span>{{ objdeil.companyInfo.hyType }}</span>
          </p>
          <p>
            规模 <span>{{ objdeil.companyInfo.scale }}</span>
          </p>
          <p>
            地区 <span>{{ objdeil.companyInfo.address }}</span>
          </p>
          <div>
            {{ objdeil.companyInfo.content }}
          </div>
        </div>
      </div>
    </div>

    <Footer />
    <el-dialog
      :visible.sync="dialogVisible"
      width="550px"
      :close-on-click-modal="false"
    >
      <div class="mantleinfo">
        <img
          :src="
            isyes
              ? require('@/assets/image/deilno.png')
              : require('@/assets/image/deilyes.png')
          "
          alt=""
        />
        <!-- <img src="@/assets/image/deilyes.png" alt="" /> -->
        <p class="title">{{ isyes ? "申请成功" : "申请失败" }}</p>
        <p class="des" v-if="!isyes">你还未上传简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" size="mini" @click="submitForm('ruleForm')"
          >提 交</el-button
        > -->
        <div @click="clcikmantle">
          {{ isyes ? "去投递记录里查看" : "去上传简历" }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser } from "@/plugins/auth.js";
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import {
  api_queryJobDetail,
  api_send,
  api_getConLat,
  api_webqueryUser,
} from "@/apis/apis.js";
export default {
  components: {
    Heard,
    Footer,
  },
  data() {
    return {
      list: [],
      center: { lng: 104.011848, lat: 30.672333 },
      zoom: 16, //缩放级别
      title: "",
      contents: "", //标签内容
      show: true, //显示标签
      scroll: true, //地图缩放
      dragging: true, //地图拖拽
      objdeil: {},
      userId: "",
      dialogVisible: false,
      isyes: false,
      filePath: "",
    };
  },

  created() {
    this.getdeil();
    this.getfilepath();
  },
  methods: {
    getfilepath() {
      api_webqueryUser({
        id: getUser().id,
      }).then((res) => {
        this.filePath = res.data.data.filePathPc;
      });
    },
    getdeil() {
      api_queryJobDetail({
        id: this.$route.params.id,
        userId: getUser().id,
      }).then((res) => {
        this.objdeil = res.data.data;
        this.contents = this.objdeil.companyInfo.address;
        this.title = this.objdeil.companyInfo.companyName;
        api_getConLat({
          name: this.objdeil.companyInfo.address,
        }).then((res) => {
          this.center = res.data.data.location;
        });
      });
    },

    gopay() {
      if (!getUser().id) {
        this.$message({
          message: "您还未登录,请先登录",
          type: "warning",
        });
        return;
      } else if (!this.filePath || this.filePath == "null") {
        this.isyes = false;
        this.dialogVisible = true;
        // this.$message({
        //   message: "您还未上传简历,请先在个人中心上传简历后投递",
        //   type: "warning",
        // });
        return;
      } else if (this.objdeil.jobInfo.sendStatus != 0) {
        this.$message({
          message: "已投递 ，请勿重复操作",
          type: "warning",
        });
        return;
      } else {
        api_send({
          userId: getUser().id,
          compId: this.objdeil.jobInfo.companyId,
          jobId: this.objdeil.jobInfo.id,
          filePath: this.filePath,
        }).then((res) => {
          if (res.data.code == 200) {
            this.isyes = true;
            this.dialogVisible = true;
            // this.$message({
            //   message: "投递成功",
            //   type: "success",
            // });
            this.getdeil();
          }
        });
      }
    },
    clcikmantle() {
      if (this.isyes) {
        this.$router.push("/resume");
      } else {
        this.$router.push("/myresume");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.tophead {
  background-color: #ebf5ff;
  height: 200px;
  .head {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .left {
      .postname {
        font-size: 28px;
        color: #000000;
        margin-bottom: 32px;
      }
      .postprice {
        font-size: 30px;
        font-weight: bold;
        color: #ff632a;
      }
    }
    .btn {
      width: 262px;
      height: 55px;
      background: #2a84e0;
      border-radius: 4px 4px 4px 4px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 55px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.body {
  margin-top: 45px;
  display: flex;
  .bodyleft {
    width: 750px;
    padding-right: 16px;
    .type {
      display: flex;
      flex-wrap: wrap;
      p {
        width: 33%;
        font-size: 18px;
        color: #979fa5;
        margin-bottom: 24px;
        span {
          color: #000;
        }
      }
      :last-child {
        width: 80%;
      }
    }
    .postdeil {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 29px;
      margin-bottom: 40px;
    }
    .call {
      margin-bottom: 80px;
      p {
        margin-bottom: 14px;
      }
    }
  }
  .bodyrg {
    flex: 1;
    .picbox {
      margin-left: 60px;
      font-size: 18px;
      color: #000000;
      display: flex;
      flex-direction: column;
      img {
        width: 140px;
        height: 140px;
        margin-bottom: 20px;
        margin-left: 28px;
      }
    }
    .ty {
      margin-top: 36px;
      p {
        margin-bottom: 22px;
        color: #979fa5;
        span {
          color: #000;
          margin-left: 14px;
        }
      }
      div {
        font-size: 16px;
        color: #000000;
        line-height: 29px;
      }
    }
  }
  .comtitle {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 26px;
  }
}
.mantleinfo {
  height: 100px;
  text-align: center;
  .title {
    font-size: 24px;
    color: #000;
    margin-top: 160px;
  }
  img {
    width: 220px;
    height: 198px;

    position: absolute;
    top: -40px;
    left: 160px;
  }

  .descinfo {
    margin: 30px;
    margin-bottom: 50px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  div {
    padding: 10px 16px;
    background-color: #51bb50;
    color: #ffffff;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>